import { Card, Button } from 'react-bootstrap'
import React from "react"

import "./profile-card.scss"


const ProfileCard = () => {
  
    return <Card >
        <div className="avatar-background"></div>
        <Card.Body>
            <div className="avatar"></div>
            <h3 className="text-center font-weight-bold avatar-name">Fabian Zamudio</h3>
            <div>
                <ul className="social-list">
                    <li><a aria-label="Linkedin profile" rel="noreferrer" target="_blank" href="https://www.linkedin.com/in/fabian-zamudio-dev/"><i className="icon-social-linkedin"></i></a></li>
                    <li><a aria-label="Github profile" rel="noreferrer" target="_blank" href="https://github.com/zamudio-fabian"><i className="icon-social-github"></i></a></li>
                    <li><a aria-label="Twitter profile" rel="noreferrer" target="_blank" href="https://twitter.com/fabian_zamudio"><i className="icon-social-twitter"></i></a></li>
                </ul>
                <div className="text-center">
                    <a href="#hireme" className="btn btn-yellow">Hire me</a>
                </div>
                <ul className="tags">
                    <li>PHP</li>
                    <li>Javascript</li>
                    <li>Java</li>
                    <li>React</li>
                    <li>Angular</li>
                    <li>Springboot</li>
                    <li>AWS</li>
                </ul>
            </div>
        </Card.Body>
        <Card.Footer >
            <Button variant="link" href="/about-me">More about me</Button>
        </Card.Footer>
    </Card>
}

export default ProfileCard
