import React from "react"
import { Row, Container, Col, Button } from "react-bootstrap"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import ProfileCard from "../components/profile-card/profile-card"
import LastPost from "../components/last-post/last-post"

const IndexPage = () => {
  return (
    <Layout>
      <SEO title="Home" />
      <Container className="mt-4">
        <Row>
          <Col lg="7" md="12">
            <LastPost></LastPost>
          </Col>
          <Col xl={{ span: 4, offset: 1 }} lg="5" md="12">
            <ProfileCard></ProfileCard>
          </Col>
        </Row>

        <Row>
          <Col className="mt-4"></Col>
        </Row>
        <Row className="justify-content-md-center">
          <Col lg="auto" className="text-center">
            <Button
              variant="primary"
              size="lg"
              className="btn-rounded"
              href="/blog"
            >
              Read more
            </Button>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default IndexPage
