import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"

import "./last-post.scss"

const LastPost = () => {
  // const result = useStaticQuery(graphql`
  //   query {
  //     allStrapiPost(filter: {slug: {eq: "react-js-redux-una-estructura-para-mejorar-la-escalabilidad"}}) {
  //       nodes {
  //         id
  //         title
  //         createdAt
  //         slug
  //         category
  //         description
  //         imagen {
  //           publicURL
  //         }
  //       }
  //     }
  //   }
  // `);

  // const post = result.allStrapiPost.nodes[0];

  const post = null

  if (!post) return <></>
  const cleanDescription = post.description.replace(/<[^>]*>?/gm, "")
  const description =
    cleanDescription.length > 400
      ? cleanDescription.substring(0, 400) + "..."
      : cleanDescription

  const image = post.imagen.publicURL
  const url = `/blog/${post.slug}`

  return (
    <>
      <h5>Último Post</h5>
      <Link to={url}>
        <div
          className="last-post-image"
          style={{ backgroundImage: "url('" + image + "')" }}
        >
          <div className="title">
            <span>{post.title}</span>
          </div>
        </div>
        <p className="mt-3 post-description">{description}</p>
      </Link>
    </>
  )
}

export default LastPost
